<template>
  <div>
    <b-form-group
      label="Nombre"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-name"
        v-model="$v.admission_profile.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-name-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="$getVisibleNames('mesh.period', false, 'Período')"
      label-for="input-period"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-select
        id="input-period"
        :options="periods"
        value-field="id"
        text-field="name"
        v-model="$v.admission_profile.period.$model"
        :state="validateState('period')"
        aria-describedby="input-period-feedback"
        size="sm"
      ></b-form-select>
      <b-form-invalid-feedback id="input-period-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(admission_profile.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "AdmissionProfileForm",
  mixins: [validationMixin],
  props: {
    AdmissionProfile: {
      type: Object,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      admission_profile: {
        id: this.AdmissionProfile
          ? this.AdmissionProfile.id
          : generateUniqueId(),
        name: this.AdmissionProfile ? this.AdmissionProfile.name : "",
        surveys: this.AdmissionProfile ? this.AdmissionProfile.surveys : [],
        period: this.AdmissionProfile ? this.AdmissionProfile.period : null,
        school: this.AdmissionProfile ? this.AdmissionProfile.school : 1,
      },
    };
  },
  validations() {
    return {
      admission_profile: {
        name: { required },
        period: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      periods: names.PERIODS,
      user: "getUser",
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.admission_profile[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.admission_profile.$touch();
      if (this.$v.admission_profile.$anyError) {
        return;
      }
      if (isNaN(this.admission_profile.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful
        .Post("/cideu/admission-profile/", this.admission_profile)
        .then((response) => {
          this.admission_profile = response;
          this.$emit("created", response);
          toast("Perfil creado.");
        });
    },
    update() {
      this.$restful
        .Put(
          `/cideu/admission-profile/${this.admission_profile.id}/`,
          this.admission_profile
        )
        .then((response) => {
          this.admission_profile = response;
          this.$emit("updated", response);
          toast("Perfil actualizado.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Perfil de Ingreso?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful
        .Delete(`/cideu/admission-profile/${this.admission_profile.id}/`)
        .then(() => {
          this.$emit("deleted", this.admission_profile);
          toast("Perfil eliminado.");
        });
    },
  },
};
</script>

<style scoped>
</style>